.list {
  margin-top: 3rem;
}

.listItem {
  & + & {
    margin-top: 3rem;
  }

  .meta {
    margin-top: 0.5rem;
  }
}

.title {
  margin: 0;
}

.meta {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: 2rem;
  color: var(--color--grey-med);
  font-size: 0.8rem;
  font-style: italic;

  > * + * {
    margin-left: 1rem;
  }
}

.author {
  display: flex;
  align-items: flex-end;
  color: var(--color--grey-med);

  &:before {
    display: inline-flex;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    background-image: url('/profile/portrait.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2rem;
    content: '';
  }
}

.series {
  margin-left: 0.5rem;
  color: var(--color--black);
  font-style: normal;
  text-transform: capitalize;
}

.contents {
  max-width: 80ch;
  margin-top: 3rem;
}

.tag {
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  background-color: var(--color--grey-light);
  color: #fff;
  font-family: var(--mono);
  font-style: normal;

  & + & {
    margin-left: 0.5rem;
  }
}

.subtitle {
  margin: 1rem 0 0;
  color: var(--color--grey-dark);
  font-size: 1rem;
}
