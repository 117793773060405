.root {
  // height: 300px;
  // font-size: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image {
  top: 0;
  right: 0;
  // width: 80%;

  // position: absolute;
  // height: 300px;
  background-color: var(--color--primary);
}

.titles {
  // margin: auto 1rem 1rem 0;
  z-index: 1;
  background-color: white;
  // max-width: 30rem;
}

.title {
  margin: 0;
  font-family: var(--sans-serif);
  font-weight: 800;
  line-height: 1.2;
}

.subtitle {
  margin: 1rem 0 0;
  font-style: italic;
}
